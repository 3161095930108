import Script from 'next/script';
import {useEffect} from 'react';
import {useRouter} from "next/router";

const GoogleAnalytics = () => {

    const router = useRouter()
    useEffect(() => {
        const handleRouteChange = (url, { shallow }) => {
            if (!shallow) {
                window.gtag('config', 'GTM-54TT5FB', {
                    page_path: url,
                });
            }
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        router.events.on('hashChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
            router.events.off('hashChangeComplete', handleRouteChange)
        }
    }, [router.events])

    return (
        <>
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=GTM-54TT5FB`}
            />
            <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'GTM-54TT5FB', {
              page_path: window.location.pathname,
            });
          `,
                }}
            />
        </>
    );
};

GoogleAnalytics.displayName = 'GoogleAnalytics';

export default GoogleAnalytics;
