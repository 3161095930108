import GoogleAnalytics from '../components/common/GoogleAnalytics';
import App from 'next/app'
import '../styles/utils/globals.scss';

function MyApp({ Component, pageProps }) {
    // usePageView();
    return (
        <>
            <GoogleAnalytics />
            <Component {...pageProps} />
        </>
    );
}

export default MyApp;
